









































import { Component, Prop, PropSync, Vue } from "vue-property-decorator";

@Component
export default class CosmopolSubstituteItem extends Vue {
  @Prop({ required: false, default: "--", type: String })
  private label: string;
  @Prop({ required: false, default: null, type: String })
  private phone: string;
  @PropSync("inputValue", { required: true, type: Boolean })
  private _inputValue: boolean;
}
